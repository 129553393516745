interface setAppQrcode{
  ios_qrcode_url: string;
  ios_apk_url: string;
  android_qrcode_url: string;
  android_apk_url: string;
  pc_qrcode_url: string;
  pc_apk_url: string;
}

export const setQrcode=(appQrcode:setAppQrcode)=>({
  type: 'SET_APP_QRCODE',
  appQrcode,
})

import { useEffect, useState, useRef } from "react";
import { Image, Dialog, Toast } from 'antd-mobile';
import titlePng2 from "@/image/title2.png";
import menuSvg from "@/image/menu.svg";
import './Navbar.less'
import { DialogShowHandler } from 'antd-mobile/es/components/dialog'
import LoginDialog from '@/component/Dialog/LoginDialog/login-dialog'
import UserDrawer from '@/component/Drawer/UserDrawer/user-drawer';
import { postLogin } from '@/api/user';
import { postActivityDetail } from '@/api/invite'
import { store } from '@/store'
import { setUserInfo } from "@/store/userInfo/action"

export const Navbar = (props:any) => {
  const handler = useRef<DialogShowHandler>()
  const childRef:any = useRef(null);
  const [titleImg] = useState(titlePng2)
  const [menuImg] = useState(menuSvg)
  const [chargeBtnClass] = useState('recharge')
  const [chargeHomeBtn] = useState('recharge')
  const [token, setToken] = useState(localStorage.getItem('token'))

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, [token])

  const loginDialog = () => {
    let disabled = false
    handler.current = Dialog.show({
      content: (
        <LoginDialog ref={childRef} />
      ),
      // closeOnAction: true,
      actions: [
        [
          {
            key: 'cancel',
            text: '取消',
            onClick: () => {
              handler.current?.close()
            },
          },
          {
            key: 'confirm',
            text: '确定',
            bold: true,
            disabled,
            onClick: () => {
              // const value = getValue(inputRef.current)
              // setUserInfo({
              //   user_id: value,
              //   avatar: '',
              //   user_nickname: 'Rechard',
              // })
              // if (!value.length) Toast.show('用户ID不能为空！')
              const { mobile, code } = childRef.current.state
              const values = {
                mobile,
                code,
              }
              if (childRef.current.verifyContent() && !disabled) {
                disabled = true
                postLogin(values).then(async (e:any) => {
                  const { token } = e;
                  if(token && token !== 'undefined') {
                    localStorage.setItem("token", token);
                    localStorage.setItem("userInfo", JSON.stringify(e));
                    let action = setUserInfo(e)

                    postActivityDetail({
                      activity_code: 'YQ001'
                    }).then(e2 => {
                      const obj = { ...e, invite_code: e2.pretty_number }
                      localStorage.setItem("userInfo", JSON.stringify(obj));
                      action = setUserInfo(obj)
                    }).finally(() => {
                      store.dispatch(action)
                      handler.current?.close()
                    })
                    
                    setToken(token)
                    Toast.show({
                      icon: 'success',
                      content: '登录成功！',
                    })

                    const searchParams = new URLSearchParams(window.location.search);
                    const page = searchParams.get('router')
                    if (page) {
                      setTimeout(() => {
                        window.location.href = page
                      }, 1000)
                    }
                  }
                })
                .finally(() => {
                  disabled = false
                })
              }
            },
          },
        ],
      ],
    })
  }

  const drawerRef:any = useRef(null);
  const openMenu = () => {
    drawerRef.current.setState({
      pageClass: 'user-drawer-page'
    })
  }

  return (
    <>
      <header className="nav-bar">
        <Image src={titleImg} fit='contain' />

        <ul>
          <li className={chargeHomeBtn}><a href='/'>首页</a></li>
          {
            token ? <>
              { ['/', 'home'].includes(window.location.pathname) ? <li className={chargeBtnClass}><a href='/recharge'>充值</a></li> : '' }
              <li onClick={openMenu}>
                <Image src={menuImg} className="svg" fit='contain' />
              </li> 
            </> :
            <li className={chargeBtnClass} onClick={loginDialog}>登录</li>
          }
        </ul>
      </header>

      <UserDrawer ref={drawerRef} parentFunc={() => setToken('')} />
    </>
  )
}
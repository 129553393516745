import { useEffect } from "react";
import { Toast } from 'antd-mobile'
import './NavBtn.less'
import { store } from '@/store'
import { isiOS, isMobile } from '@/utils/common'
// 图片
import titlePng from "@/image/title.png";
import titlePng2 from "@/image/title2.png";
import menuSvg from "@/image/menu.svg";
import menuSvg2 from "@/image/menu2.svg";

const NavBtn = (props:any) => {
  const setHeader = (navBar:HTMLElement, lis:Array<any>, i:number) => {
    const rechargeBtn = navBar.querySelectorAll('.recharge')[1] as HTMLElement
    const logoImg = navBar.querySelector('.adm-image>.adm-image-img') as HTMLImageElement
    const svgBtn = navBar.querySelector('.svg>.adm-image-img') as HTMLImageElement

    rechargeBtn.classList.add("black-btn");
    logoImg.src = titlePng
    svgBtn.src = menuSvg

    if (i === 0 || i === lis.length - 1) {
      rechargeBtn.classList.remove("black-btn");
      logoImg.src = titlePng2

      if (i === lis.length - 1) {
        svgBtn.src = menuSvg2
      }
    }
  }

  useEffect(() => {
    const pictureWall = document.querySelector('.picture_wall') as HTMLElement;
    const firstImg = document.querySelector('.picture_wall>.header-module') as HTMLElement;
    const imgHeight = firstImg.scrollHeight;
    const lis = document.querySelectorAll('.nav-btn>li') || [];
    const arrowBox = document.querySelectorAll('.arrow-box') || [];
    const memePng = document.querySelectorAll('.meme-png') || [];

    const smoothScrollTo = (endY:number) => {
      pictureWall.style.transform = `translate3d(0px, ${-endY}px, 0px)`
    };

    const changeStyle = (lis:any, scroll:number, imgHeight:number) => {
      const navBtn = document.querySelector('.nav-btn') as HTMLElement
      const activeBtn = document.querySelector('.nav-btn>.active-nav-btn') as HTMLElement
      const downBtn = document.querySelector('.nav-btn + .download-btn') as HTMLElement
      const navBar = document.querySelector('.nav-bar') as HTMLElement

      activeBtn && activeBtn.classList.remove('active-nav-btn')

      let i = Math.floor(scroll / imgHeight)
      i = i >= lis.length ? lis.length - 1 : i >= 0 ? i : 0
      lis[i]?.classList.add('active-nav-btn')
      if (i === lis.length - 1) {
        navBtn.classList.add('unactive-nav-btns')
        downBtn.classList.add('hidden-dom')
      } else {
        navBtn.className.includes('unactive-nav-btns') && navBtn.classList.remove('unactive-nav-btns')
        downBtn.classList.remove('hidden-dom')
      }

      if (i === 0 || i === lis.length - 1) {
        navBar.classList.remove('reset-nav-bar')
      } else {
        navBar.classList.add('reset-nav-bar')
      }

      changeBgAnim(i)
      setHeader(navBar, lis, i)
    }

    const changeBgAnim = (i:number = 0) => {
      // 每次都先清空原先动画样式
      firstImg.querySelector('.first-star')?.classList.remove('show-first-star')
      firstImg.querySelector('.second-star')?.classList.remove('show-second-star')
      firstImg.querySelector('.third-star')?.classList.remove('show-third-star')
      Array.from(arrowBox)?.map(e => {
        return e.classList.remove('active')
      })
      Array.from(memePng)?.map(e => {
        return e.classList.remove('active')
      })

      if (i === 0) {
        firstImg.querySelector('.first-star')?.classList.add('show-first-star')
        firstImg.querySelector('.second-star')?.classList.add('show-second-star')
        firstImg.querySelector('.third-star')?.classList.add('show-third-star')
      } else if (i > 0 && i < 6) {
        arrowBox[i - 1]?.classList.add('active')
        memePng[i - 2]?.classList.add('active')
      }
    }

    const reload = () => {
      window.location.reload()
    }

    const listenerTouch = () => {
      let startX = 0;
      let startY = 0;
      let isBottom = false;
      let scroll = 0;

      (lis as any).forEach((dom:any, i:number) => {
        dom.addEventListener('click', (e:any) => {
          e.stopPropagation();

          scroll = i * imgHeight
          smoothScrollTo(scroll);
          changeStyle(lis, scroll, imgHeight)
        })
      })

      pictureWall.addEventListener(
        'touchstart',
        (e) => {
          // e.preventDefault();
          startX = e.changedTouches[0].pageX;
          startY = e.changedTouches[0].pageY;
          isBottom = (document.querySelector('.footer-module') as HTMLElement).getBoundingClientRect().top < 10
        },
        { passive: false },
      );

      pictureWall.addEventListener(
        'touchend',
        (e) => {
          // e.preventDefault();
          let moveEndX = e.changedTouches[0].pageX;
          let moveEndY = e.changedTouches[0].pageY;
          let X = moveEndX - startX;
          let Y = moveEndY - startY;

          if (Math.abs(Y) > Math.abs(X) && Y < 0) {
            if (!isBottom) {
              scroll += imgHeight
            }
          } else if (Math.abs(Y) > Math.abs(X) && Y > 0 && scroll > 0) {
            scroll -= imgHeight
          }

          const scrollHeight = (lis.length - 1) * imgHeight
          scroll = scroll > scrollHeight ? scrollHeight : scroll

          smoothScrollTo(scroll);
          changeStyle(lis, scroll, imgHeight)
        },
        { passive: false },
      );
    }

    const listenerMouseMove = () => {
      let isDragging = false;
      let startY = 0;
      let index = 0;

      (lis as any).forEach((dom:any, i:number) => {
        dom.addEventListener('click', (e:any) => {
          e.stopPropagation();

          index = i
          smoothScrollTo(i * imgHeight);
          changeStyle(lis, i * imgHeight, imgHeight);
        })
      })

      pictureWall.addEventListener('mousedown', (e) => {
        isDragging = true;
        startY = e.clientY;
      });
      
      pictureWall.addEventListener('mouseup', () => {
        isDragging = false;
      });

      pictureWall.addEventListener('mousemove', (e) => {
        if (isDragging) {
          isDragging = false; //立马改为false不然多次触发
          const currentY = e.clientY;
          if (currentY < startY) {
            if (index < lis.length - 1) index++
          } else {
            if (index > 0) index--
          }

          smoothScrollTo(index * imgHeight);
          changeStyle(lis, index * imgHeight, imgHeight)
        }
      });

      pictureWall.addEventListener("mousewheel", (e:any) => {
        // 处理滚轮事件
        if (e.wheelDeltaY > 0) {
          if (index > 0) index--
        } else {
          if (index < lis.length - 1) index++
        }

        smoothScrollTo(index * imgHeight);
        changeStyle(lis, index * imgHeight, imgHeight)
      });
    }

    // window.scrollTo(0, 0); //每次刷新返回到第一张图
    changeBgAnim(); //触发动画

    if (isMobile()) {
      listenerTouch()
    } else {
      listenerMouseMove()

      // 因为做自适应，屏幕缩放会导致像素位置偏移，故监听屏幕变动就强制刷新
      window.addEventListener('resize', reload);
    }

    // 组件卸载时移除监听器
    return () => {
      window.removeEventListener('resize', reload);
    }
  }, []);

  const downBtnFunc = (e:any) => {
    e.stopPropagation()

    const qrcode:{[index:string]:any} = store.getState().qrcode.appQrcode;

    const url = !isMobile() ? qrcode.pc_apk_url : isiOS ? qrcode.ios_apk_url : qrcode.android_apk_url
    if (!url) Toast.show('下载失败，请稍后刷新页面再试！')
    else window.location.href = url && url !== 'undefined' ? url : '/'
  }

  return (
    <>
    <ul className="nav-btn">
      <li className="active-nav-btn"></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  
    <span className="download-btn" onClick={downBtnFunc}>下载UU语音</span>
    </>
  )
}

export default NavBtn